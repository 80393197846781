import axios from 'axios';
import {
  AUTHENTICATE_BY_GAAP_URL,
  AUTHENTICATE_BY_JWT_URL,
  AUTHENTICATION_REFRESH_URL,
  CONSENT_URL,
  PISP_URL
} from '../api/ConstantsURL';
import '../assets/fonts/Ubuntu-Regular.ttf';
import { config } from '../config';
import { LOGIN_PAGE } from '../routes/ConstantsRoutes';

const createPureAxiosInstance = () => {
  return axios.create({
    baseURL: `${config.contextRoot}`
  });
};

const axiosInstance = createPureAxiosInstance();

const isAuthenticationRequest = (request) => {
  return request.url.includes(AUTHENTICATE_BY_JWT_URL);
};
const isAuthenticationRequestGaap = (request) => {
  return request.url.includes(AUTHENTICATE_BY_GAAP_URL);
};

const isConsentRequest = (request) => {
  return request.url.includes(CONSENT_URL);
};

const isPispRequest = (request) => {
  return request.url.includes(PISP_URL);
};

const needRefreshAuthentication = () => {
  try {
    const tokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));
    let currentDate = new Date();
    const expirationDate = new Date(tokenInfo?.tokenExpiration);
    console.log('tokenInfo:', tokenInfo);
    console.log('actual date:', currentDate);
    console.log('expirationDate:', expirationDate);
    return currentDate > expirationDate;
  } catch (e) {
    console.error(e);
  }
  return true;
};

const refreshToken = () => {
  const refreshToken = async () => {
    const tokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));
    console.log('refresh', tokenInfo);
    let auth = { headers: { Authorization: tokenInfo.token } };
    const { data } = await createPureAxiosInstance().get(AUTHENTICATION_REFRESH_URL, auth);
    const newToken = JSON.stringify({
      token: data.token,
      tokenExpiration: data.expirationDate,
      clientId: tokenInfo.clientId,
      userId: tokenInfo.userId,
      role: tokenInfo.role
    });
    console.log('newToken- refresh:', newToken);
    localStorage.setItem('TokenInfo', newToken);
  };
  refreshToken().catch((error) => {
    cleanAndRedirect(error);
  });
};

function addJWTToken(request) {
  const tokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));
  console.log('ici', tokenInfo);
  request.headers.Authorization = tokenInfo.token;
  return request;
}

function addGaapToken(request) {
  const tokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));
  request.headers.Authorization = 'GAAP,' + tokenInfo.username;
  return request;
}

function cleanAndRedirect(error) {
  localStorage.clear();
  console.error(error);
  window.location.href = LOGIN_PAGE;
}

axiosInstance.interceptors.request.use(
  (request) => {
    console.log('Interceptor');
    //Not authenticated redirect to login page
    if (isAuthenticationRequest(request) || isAuthenticationRequestGaap(request)) {
      console.log('Interceptor : Login EndPoint Authorization not mandatory');
      return request;
    }

    if (isConsentRequest(request) || isPispRequest(request)) {
      console.log('Interceptor : Public endpoint Authorization not mandatory');
      return request;
    }
    const authInfo = JSON.parse(localStorage.getItem('AuthenticationInfo'));
    const tokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));
    console.log('Interceptor: AuthenticationInfo', authInfo);
    console.log('Interceptor: tokenInfo', tokenInfo);
    if (authInfo?.isLoggedIn || tokenInfo?.tokenExpiration) {
      if (authInfo?.authMethod === 'JWT') {
        console.log('Interceptor: JWT authentication');
        if (needRefreshAuthentication(request)) {
          console.log('Interceptor: Authorization refreshed');
          refreshToken();
          return request;
        }
        console.log('Interceptor: Add Authorization Token');
        return addJWTToken(request);
      } else if (authInfo?.authMethod === 'GAAP') {
        console.log('Interceptor: Gaap authentication');
        return addGaapToken(request);
      }
    } else {
      cleanAndRedirect('Interceptor: Connexion Mandatory');
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      cleanAndRedirect('Interceptor: Unauthorized');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
